html,
body,
.app {
	height: 100%;
	overflow: hidden;
}

#root {
	height: 100%;
  }

.portal li {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: unset;
}

.portal li > div {
	width: 100%;
}

.portal li p {
	font-family: 'Roboto', sans-serif !important;
	font-size: 0.875rem !important;
	color: inherit !important;
	letter-spacing: 0.015625rem !important;
}

.portal li p.subTitle {
	font-size: 0.6875rem !important;
    font-weight: 400 !important;
}

.portal li span  {
	font-family: 'Roboto', sans-serif !important;
	font-size: 0.875rem !important;
	color: inherit !important;
	letter-spacing: 0.015625rem !important;
}

.app {
	height: 100vh;
	background-color: #eee;
	padding: 1em;
}

.react-draggable-transparent-selection .track-horizontal {
	display: none;
}

.react-draggable-transparent-selection .track-vertical {
	display: none;
}

.message__self {
	border-left: 2px solid orange;
	padding-left: 8px;
}

.progress-bar {
  margin-top: 0.3em;
}

.myVideo {
	position: relative;
	display: inline-flex;
    vertical-align: top;
    flex-wrap: wrap;
	width: 50%;
	height: 50%;
}

.remoteVideo {
	position: relative;
    display: inline-flex;
    vertical-align: top;
    flex-wrap: wrap;
	width: 50%;
	height: 50%;
}

.screenShareButton {
	position: absolute;
	right: 10px;
	top: 10px;
	border-radius: 50%;
	background-color: orange;
	width: 25px;
	height: 25px;
	z-index: 30;
}


/*typing animation template from internet, needs to be changed latter */
.user__typing {
	font-style: italic;
	font-weight: bold;
	margin-right: 3px;
  }
  
  .typing {
	width: 5em;
	height: 2em;
	position: relative;
	padding: 10px;
	margin-left: 5px;
	background: #e6e6e6;
	border-radius: 20px;
  }
  
  .typing__dot {
	float: left;
	width: 8px;
	height: 8px;
	margin: 0 4px;
	background: #8d8c91;
	border-radius: 50%;
	opacity: 0;
	animation: loadingFade 1s infinite;
  }
  
  .typing__dot:nth-child(1) {
	animation-delay: 0s;
  }
  
  .typing__dot:nth-child(2) {
	animation-delay: 0.2s;
  }
  
  .typing__dot:nth-child(3) {
	animation-delay: 0.4s;
  }
  
  @keyframes loadingFade {
	0% {
	  opacity: 0;
	}
	50% {
	  opacity: 0.8;
	}
	100% {
	  opacity: 0;
	}
  }
  